import Slider from '../components/Slider';
//import TagList from '../components/TagList';
import parse from 'html-react-parser';
import { HiArrowCircleRight } from "react-icons/hi";

const PortfolioList = ({ data }) => {
    return (
        <>
            {data.map((proj,i) => (
                <article key={proj.name} className="text-box">
                    <div>
                        { proj.media.length && 
                            <Slider folder="media" imgData={[...proj.media]}/>    
                        }
                        <div className="px-4 py-3">
                            <h4>
                                {proj.url ? <a href={proj.url} title={proj.title} target="_blank" rel="noreferrer">
                                    {proj.title} <HiArrowCircleRight className="inline text-base align-baseline" />
                                </a> : proj.title}
                            </h4>                  
                            { proj.body ? proj.body.map((para) => (
                                <p key={para} className="text-sm">{ parse(para) }</p>
                            )):''}
                            {/* <TagList key={`tag-${proj.title}`} title="MY CONTRIBUTIONS" items={proj.tags}/> */}
                        </div>
                    </div>
                </article>
            ))}
        </>
    )
}

export default PortfolioList;