import PortfolioList from '../components/PortfolioList';
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchPortfolio } from '../features/portfolioSlice'

const PortfolioListPage = () => {
    const dispatch = useDispatch();
    const dataStatus = useSelector(state => state.portfolio.status);
    const data = useSelector(state => state.portfolio.PortfolioData);

    const [portfolioData, setPortfolioData] = useState([]);
    const { category } = useParams();

    useEffect(() => {
        if (dataStatus === 'idle') {
          dispatch(fetchPortfolio())
        }
    }, [dataStatus, dispatch])

    useEffect(() => {
        if(data && data.length > 0){
            let newPortfolioData = data;
            if (category && category !== 'all') {
                newPortfolioData = data.filter(p => p.category === category);
            }else{
                newPortfolioData = data;
            }
            newPortfolioData = newPortfolioData.sort((a, b) => a.weight - b.weight)
            setPortfolioData(newPortfolioData);
            
        }
    }, [category,data,dataStatus]);

    return (
        <>
            <div id="Portfolio" className="max-w-4xl text-center mx-auto">
                <h3 className="text-white">{portfolioData.length > 0 ? category.replace('-',' ') : 'Portfolio'}</h3>
                <div className="grid md:grid-cols-2 gap-8">
                { portfolioData.length > 0 ? <PortfolioList data={portfolioData}/> :'' }
                </div>
            </div>
        </>
    )
}

export default PortfolioListPage; 