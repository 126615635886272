import { Link  } from 'react-router-dom';

const catList = [
    {
        name: 'websites',
        title: 'Websites'
    },
    {
        name: 'applications',
        title: 'Applications'
    },
    {
        name: 'special-features',
        title: 'Special Features'
    }
]
const SubNav = (nav) => {
    return (
        <>
            {catList.map((obj) => (
                <Link key={`subnav-${obj.name}`} className={`leading-4 inline-block text-xs  uppercase text-center font-black px-1 hover:text-dark-gray ${(nav.active === obj.name) ? 'text-dark-gray': 'text-mid-gray'}`} to={`/portfolio/${obj.name}`} title={obj.title}>{ obj.title }</Link>             
            ))}
        </>
    )
}

export default SubNav;