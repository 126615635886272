import './App.css';
import { BrowserRouter, Navigate, Routes, Route} from 'react-router-dom';

import Header from './components/Header';
import Footer from './components/Footer';

import HomePage from './pages/HomePage';
import PortfolioListPage from './pages/PortfolioListPage';
import ExperiencePage from './pages/ExperiencePage';
import NotFoundPage from './pages/NotFoundPage';


function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Header></Header>
        <div id="page-body">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/portfolio" element={<Navigate to="/portfolio/websites" replace />} />
            <Route path="/portfolio/:category" element={<PortfolioListPage />} />
            <Route path="/skills" element={<ExperiencePage />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </div>
        <Footer></Footer>
      </div>
    </BrowserRouter>
  );
}

export default App;
