import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchPortfolio = createAsyncThunk('portfolio/fetchPortfolio', async () => {
    const res = await axios.get('/portfolio-data.js')
    return res.data
})

const portfolioSlice = createSlice({
  name: 'portfolio',
  initialState: {
    filteredProjects: [],
    PortfolioData: [],
    status: 'idle'
  },
  reducers: {
    getProjectsByCategory: (state, action) => {
      const projects = state.data.find(proj => proj.category === action.payload);
      state.filteredProjects = (projects) ? projects : [];
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchPortfolio.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(fetchPortfolio.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.PortfolioData = action.payload;
      })
      .addCase(fetchPortfolio.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
  }
})

export const { getProjectsByCategory } = portfolioSlice.actions
export default portfolioSlice.reducer;