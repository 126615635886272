import React, {useState } from 'react';
import { LazyLoadImage } from "react-lazy-load-image-component";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi";

const Slider = ({ imgData, imgClass, h }) => {
    const [active,setActive] = useState(0);

    function previousSlide(){
        if(active > 0){
            setActive(active-1);
        }else{
            setActive(imgData.length-1);
        }
    }

    function nextSlide(){
        if(active === imgData.length-1){
            setActive(0);
        }else{
            setActive(active+1);
        }
    }

    function showSlide(id){
        setActive(id);
    }

    return (
        <>
            <div className={imgClass ? imgClass :`slideshow relative w-full bg-green p-4`}>
                <div className={`overflow-hidden w-full relative ${h === "full" ? 'aspect-square' : 'aspect-[3/2]'}`}>
                {imgData.map((obj,i) => (
                    <div key={obj.url} className={`absolute w-full h-0  ${h === "full" ? 'pb-[100%]' : 'pb-[66%]'} overflow-hidden transition-opacity duration-500 ${active === i ? 'opacity-100' : 'opacity-0' }`}>
                        <LazyLoadImage className="block w-full h-auto" src={obj.url} alt={(obj.title) ? obj.title : ''}/>
                        { obj.caption ? <p>{obj.caption}</p> : null}
                    </div>
                ))}
                </div>
                { imgData.length > 1 ?
                <div className="slideshow-nav">
                    <button className="arrow previous" title="Previous" onClick={previousSlide}>
                        <HiChevronLeft/>
                    </button>
                    <button className="arrow next" title="Next" onClick={nextSlide}>
                        <HiChevronRight/>
                    </button>
                    <div className="dot-nav leading-3 absolute bottom-1 left-0 w-full z-10 text-center">
                        {imgData.map((obj,i) => (
                            <button key={`dot-${i}`} onClick={(e) => {showSlide(i)}} className={active === i ? 'active' : '' } title={'Slide '+i}/>
                        ))}
                    </div>
                </div>
                :''}
            </div>
        </>
    )
}

export default Slider;